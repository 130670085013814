<template>
    <div>
        <div v-if="!isLogin()" class="main-box-register ">
            <div class="box-register main-overlay-np">
                <div>
                    <div v-if="success" class="text-center">
                        <div class="regis_success">
                        <i class="fas fa-check" style="font-size: 48px;color: #fff;"></i> 
                        </div>
                        <div style="font-weight: 600;font-size:1rem;color:#000" v-html="message"></div>
                    </div>
                    
                    <form v-if="!success" @submit.prevent="submit()" >
                        <div class="mb-4 text-center"><router-link to="/"><img  src="../../assets/images/logo.svg" style="height: 40px; "></router-link></div>
                        <div class="row">
                            <!-- <template v-for="(item, key) in form_data">
                                <div class="col-12" v-if="item.show" :key="key">
                                    <div class="form-input mb-3">
                                        <p class="title-top-input">{{item.title}}<span class="text-red" v-if="item.required">*</span></p>
                                        <select class="form-select form-cs" v-if="item.type_input === 'select'" :required="item.required" :disabled="item.list.length == 0" v-model="item.value" @change="onchange(key)" :name="key">
                                            <option value="" >--- select {{key}} ---</option>
                                            <option v-for="(item, key) in item.list" :key="key" :value="item">{{item}}</option>
                                        </select>
                                        <input :type="item.type_input" class="form-control form-cs" :required="item.required" placeholder="Write here" v-else v-model="item.value" @change="onchange(key)" :name="key">
                                    </div>
                                </div>
                            </template> -->
                            <div class="col-12">
                                <div class="form-input mb-2">
                                    <p class="title-top-input">Name<span class="text-red">*</span></p>                                        
                                    <input type="text" class="form-control form-cs" required="true" placeholder="Write here" v-model="form_data.name" >
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-input mb-2">
                                    <p class="title-top-input">Surname<span class="text-red">*</span></p>                                        
                                    <input type="text" class="form-control form-cs" required="true" placeholder="Write here" v-model="form_data.lastname" >
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-input mb-2">
                                    <p class="title-top-input">Company</p>                                        
                                    <input type="text" class="form-control form-cs" placeholder="Write here" v-model="form_data.company" >
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-input mb-2">
                                    <p class="title-top-input">Job Title<span class="text-red">*</span></p>                                        
                                    <input type="text" class="form-control form-cs" required="true" placeholder="Write here" v-model="form_data.jobtitle" >
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-input mb-2">
                                    <p class="title-top-input">Work email<span class="text-red">*</span></p>                                        
                                    <input type="email" class="form-control form-cs" required="true" placeholder="Write here" v-model="form_data.email" @change="checkemail">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-input mb-2">
                                    <p class="title-top-input">Country<span class="text-red">*</span></p> 
                                    <select class="form-select form-cs" required="true" :disabled="list_country.length == 0" v-model="form_data.mailing_country" >
                                        <option value="" >--- select  ---</option>
                                        <option v-for="(item, key) in list_country" :key="key" :value="item.country_name">{{item.country_name}}</option>
                                    </select>                                       
                                </div>
                            </div>
                            <div class="col-12" v-if="location_show">
                                <div class="form-input mb-2">
                                    <p class="title-top-input">Location<span class="text-red">*</span></p> 
                                    <select class="form-select form-cs" required="true" v-model="form_data.location_novartis" >
                                        <option value="" >--- select location ---</option>
                                        <option v-for="(item, key) in list_location" :key="key" :value="item">{{item}}</option>
                                    </select>                                       
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-input mb-2">
                                    <p class="title-top-input">Invitation code</p>                                        
                                    <input type="text" class="form-control form-cs" placeholder="Write here" v-model="form_data.invitation_code" >
                                </div>
                            </div>
                        </div>
                        <div class="main-input mt-3 mb-4">
                            <div class="box-check" style="background:none;padding:0;">
                                <label>
                                    <input type="checkbox" class="checkbox-input" v-model="accept" :required="true" name="accept">
                                    <span class="check-recherche"></span>
                                    <div class="txt-check text-dark">
                                        I accept the <a target="_blank" rel="noopener" style="text-decoration: underline!important;color:#0d6efd" href="https://www.prioritis.com/prioritis-terms-and-conditions">terms and conditions</a> 
                                        and <a target="_blank" rel="noopener" style="text-decoration: underline !important;color:#0d6efd" href="https://www.prioritis.com/Privacy-Policy">privacy policy.</a> <span style="color:red">*</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="button-search">
                            <button class="btn-orange extra-long w-50" :class="!accept ? 'btn-disabled' : ''" type="submit" :disabled="!accept">SUBMIT</button>
                        </div>
                        
                    </form>
                </div>
                <div v-if="loading" class="overlay">
                    <div class="loadding_circle"></div>
                </div>

            </div>

        </div>

        <div class="modal fade" ref="confirmlogout" id="modal_error" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-cs">
                <div class="modal-content">
                    <div class="confirm-feedback">
                        <div class="regis_success" style="background: #f51717;width: 50px">
                            <i class="fas fa-times" style="font-size: 28px;"></i>
                        </div>
                        <span v-html="message"></span>
                        <div class="box-area">
                            <div class="box-btn-menu">
                                <button class="send btn btn-orange" style="margin: auto;" data-bs-dismiss="modal" >  OK </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { computed, reactive, ref, onMounted, onUnmounted  } from 'vue'
import { useStore } from 'vuex'
import { Modal } from 'bootstrap'
import { useRouter, useRoute } from 'vue-router';
import { isLogin, homePage } from "../../utils";

export default {   
    setup(){
        const router = useRouter()
        const route = useRoute()
        if (isLogin()) {       
            router.push(homePage()); 
        }
        const store = useStore()
        const form_data = reactive({
                name : "",
                lastname : "",
                mailing_country : "",
                jobtitle : "",
                email : "",
                company : "",
                location_novartis : "",
                invitation_code : "",
        });
        const accept = ref(false);
        store.dispatch('auth/get_list_country')
        const list_country = computed(() => store.state.auth.list_country); 
        if(route.query.invitation_code){
            form_data.invitation_code = route.query.invitation_code
        }

        /**
         * On click SUBMIT 
         */
        const loading = ref(false);
        const success = ref(false);
        const message = ref("");
        const submit = () => {
            console.log(form_data)
            //modal.value.show();
            loading.value = true
            store.dispatch("auth/register", form_data ).then(
                (res) => {
                    console.log(res)
                    message.value = res.message;
                    success.value = true
                    loading.value = false
                },
                (error) => {
                    if(error.response && error.response.data && error.response.data.message){
                        message.value = error.response.data.message;
                    }else{
                        message.value = error
                    }
                    loading.value = false
                    modal.value.show();                              
                }
            );
        }



        const location_show = ref(false)
        const list_location = ["Global", "CONEXTS", "Region Europe", "Region APMA", "Region AMACO", "Region LACan", "CPO"];
        const checkemail = () => {
            console.log(form_data.email)   
            const text = form_data.email.toLowerCase();            
            if (text.search("@novartis.com") !== -1) {  
                location_show.value = true;
            }else{
                form_data.location_novartis = "";
                location_show.value = false;
            }
        }

        const modal = ref(null);
        onMounted(() => {
            modal.value = new Modal(document.getElementById('modal_error'));             
        })

        onMounted(() => {
            document.body.classList.add('pt-0')
        });
        onUnmounted(() => {
            document.body.classList.remove('pt-0')
        })


        return {form_data, accept, list_country, submit, location_show, list_location, loading, success, message, checkemail, isLogin}
    },
    
    
    methods: {
        // submit(){
        //     let datas = {}
        //     for(let item in this.form_data){
        //         if(this.form_data[item].value){
        //             datas[item] = this.form_data[item].value
        //         }
        //     }
        //     console.log(datas)
        // },
        // onchange(key){
        //     if(key == 'work_email'){
        //         console.error(this.form_data[key].value.search('@novartis.com') > -1)
        //         if(this.form_data[key].value.search('@novartis.com') > -1){
        //             this.form_data['location'].show = true
        //         }
        //         else{
        //             this.form_data['location'].show = false
        //             this.$forceUpdate()
        //         }
        //     }
        // }
    }
}

</script>
